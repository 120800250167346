<template>
  <body style="position: relative;">
  <div id="wrap">
		<section>
			<headerLayout></headerLayout>
		</section>
    <main>
        <section class="sub-visual">
            <div class="innWrap">
                <div class="txt">
                    <h2>요금현황</h2>
                    <!-- <p>국내 최대 용량의 기업전용망을 통한 고품질 인터넷 전용회선 서비스</p> -->
                </div>
                <div class="location">
                    <ul>
                        <li><a href="#">MY</a></li>
                        <li><a href="#">요금/결제정보</a></li>
                        <li><span>요금현황</span></li>
                    </ul>
                </div>
            </div>
        </section>

        <section class="sub-section feePrstus">
            <div class="innWrap lineBox">
                <div class="form-list">
                    <!-- <div class="form-Item">
                        <label class="required" for="fee-title-01">청구계정 ID</label>
                        <div class="fcon select" id="fee-title-01">
                            <span class="placeholder"><span class="fcGy">전체</span></span>
                            <div class="list">
                                <ul v-for="item in accountInfo" :key="item.billAccId" :value="item.billAccId" @click="selectValue($event, 'ID')">
                                    <li>{{item.billAccId}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="form-Item">
                        <label class="required" for="fee-title-01">서비스계약 ID</label>
                        <div class="fcon select" id="fee-title-01">
                            <span class="placeholder"><span class="fcGy">전체</span></span>
                            <div class="list">
                                <ul v-for="item in userSvcInfo" :key="item.scn" :value="item.scn" @click="selectValue($event, 'SCN')">
                                    <li>{{item.scn}}</li>
                                </ul>
                            </div>
                        </div>
                    </div> -->
                    <div class="form-Item">
                        <label class="required" for="fee-title-02">청구계정ID</label>
                        <div class="fcon multi">
                            <div class="select w468px" id="fee-title-02">
                                <span class="placeholder"><span class="fcGy">청구계정ID</span></span>
                                <div class="list">
                                    <ul>
                                    <li v-for="item in accountInfo" :key="item.billAccId" :value="item.billAccId" @click="selectValue($event, 'ID')">{{item.billAccId}}</li>
                                </ul>
                                </div>
                            </div>
                            <!--
                            <label class="" for="fee-title-02" style="margin-top: 6px; font-size: 18px; color:black;">서비스계약ID</label>
                            <div class="select w468px" id="fee-title-02">
                                <span class="placeholder"><span class="fcGy">서비스계약ID</span></span>
                                <div class="list">
                                    <ul>
                                    <li v-for="item in userSvcInfo" :key="item.scn" :value="item.scn" @click="selectValue($event, 'SCN')">{{item.scn}}</li>
                                </ul>
                                </div>
                            </div>
                            -->
                            <label class="" for="fee-title-02" style="margin-top: 6px; font-size: 18px; color:black;">서비스번호</label>
                            <div class="select w468px" id="fee-title-02">
                                <span class="placeholder"><span class="fcGy">서비스번호</span></span>
                                <div class="list">
                                    <ul>
                                    <li v-for="item in userSvcInfo" :key="item.scn" :value="item.scn" @click="selectValue($event, 'SCN')">{{item.svctypename + (item.llnum == '' ? ' / - / ' : ' / '+ item.llnum) }}</li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-Item">
                        <label class="required" for="fee-title-02">기간</label>
                        <div class="fcon multi">
                            <div class="select w468px" id="fee-title-02">
                                <span class="placeholder"><span class="fcGy">년도</span></span>
                                <div class="list">
                                    <ul>
                                        <li v-for="item in yy" :key="item" @click="selectValue($event, 'YY')" :value="item">{{item}}년</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="select w468px" id="fee-title-02">
                                <span class="placeholder" ref="mm"><span class="fcGy">월</span></span>
                                <div class="list">
                                    <ul>
                                        <li v-for="item in mm" :key="item" @click="selectValue($event, 'MM')" :value="item">{{item}}월</li>
                                    </ul>
                                </div>
                            </div>
                             <a @click="chageBySvcWebService" class="btn-md02 btn-primary">조회</a>
                        </div>
                    </div>
                    <p class="pdl320 ln1">※ 이번달 확정 요금은 <span class="fcPink fw600">다음달 말</span> 확인 가능합니다</p>
                </div>
                <div class="total grayBox" v-show="chageWebService_res">
                    <p class="title">전체 서비스의 <span>{{year_res}}년 {{month_res}}월</span> 요금</p>
                    <div class="amount">
                        <p>{{this.total_res.dcBefAmt | comma}}<span class="fz20 fcPurple2">(이용 금액)</span> {{this.total_res.dcAmt | comma}}<span class="fz20 fcPurple2">(할인 금액)</span><span></span></p>
                        <p class="equals"></p>
                        <p class="fz34">{{this.total_res.payAmt | comma}}<span class="fw600">원</span><span class="fz20 fcPurple2">(부가세 별도)</span></p>
                    </div>
                </div>

                <h5 v-show="chageWebService_res">서비스별 이용 금액 </h5>
                <!-- <h5 v-show="chageWebService_res">서비스별 이용 금액 </h5> -->
                <div class="svcAmount grayBox" v-show="chageWebService_res" >
                <!-- <div class="svcAmount grayBox" v-show="chageWebService_res"> -->
                  <dl style="display: flex;justify-content: space-between; height: 48px;">
                    <dt style="width: 450px;">서비스 항목</dt>
                    <dt style="width: 130px; text-align: left;">서비스</dt>
                    <dt style="text-align: right;">이용 금액</dt>
                    <dt style="text-align: right;">할인 금액</dt>
                    <dt style="text-align: right;">납부 금액</dt>
                  </dl>
                  <!-- <div style="display: flex; justify-content: space-evenly;">
                  <p>이용 금액</p>
                  <p>할인 금액</p>
                  <p>납부 금액</p>
                  </div> -->
                    <dl v-for="item in chageWebService_res" :key="item.acrndItemId" v-if="item.dtlCboType == 'COMMON_DATA' || item.dtlCboType == 'DATA'">
                        <dt style="width: 450px;">{{item.acrndItemNm}}</dt>
                        <dt style="width: 130px;">{{item.prodLobNm}}</dt>
                        <!-- <dd class="agc">할인전금액</dd> -->
                        <dd class="agr amount">{{item.dcBefAmt | comma}}<span>원</span></dd>
                        <!-- <dd class="agc">할인 금액</dd> -->
                        <dd class="agr amount">{{item.dcAmt | comma}}<span>원</span></dd>
                        <!-- <dd class="agc">납부금액</dd> -->
                        <dd class="agr amount">{{item.payAmt | comma}}<span>원</span></dd>
                    </dl>
                </div>
                <p class="asterisk_red mgt20" >※ 무약정으로 가입 후 해지 할 경우 할인 반환금이 발생하지 않습니다.</p>
                <!-- <p class="asterisk_red mgt20" v-show="chageBySvcWebService_res">※ 무약정으로 가입 후 해지 할 경우 할인 반환금이 발생하지 않습니다.</p> -->
            </div>
        </section>
    </main>
    <section>
      <footerLayout></footerLayout>
    </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from './common/Header.vue'
import FooterLayout from './common/Footer.vue'
import moment from 'moment'
export default {
  components: {
    HeaderLayout,
    FooterLayout
  },
  data () {
    return {
      yy: [],
      mm: [],
      //api 결과 값
      userInfo: '',
      userSvcInfo: '',
      contactInfo: '',
      accountInfo: '',
      selectEmail: '',
      selectMobile: '',
      selectPhone: '',
      partyIdNumber1: '',
      partyIdNumber2: '',
      encPartyIdNumber1: '',
      encPartyIdNumber2: '',
      partyDetailTypeCd: '',
      partyName: '',
      custid: '',
      txnByBillTgtYm_res: '',
      chageWebService_res: '',
      total_res: '',
      chageBySvcWebService_res: '',

      billAccId_sel: '',
      svcContId_sel: '',
      year_sel: '',
      month_sel: '',
      year_res: '',
      month_res: '',
      resultFlag: '',
      
      lastYear: '',
      thisYear: '',
      lastMonth: '',
      thisMonth: '',
      sameYear: false
    }
    
  },
  created: function () {

  },
  mounted: async function () {
    await this.getAuthContactInfo()
    await this.getUserInfo()
    await this.getUserSvcInfo()
    await this.getAccountListInfo()
    this.getYearList()
  },
  methods: {
    getMonthList(year) {
      let i ,max;

      if(this.sameYear){
        i = this.lastMonth
        max = this.thisMonth
      }else{
        if(this.thisYear == year){
          i = 1
          max = this.thisMonth
        }else{
          i = this.lastMonth
          max = 12
        }
      }
      for(i; i <= max; i++) {
        if(i<10) {
          this.mm.push('0'+i)
        } else {
          this.mm.push(i)
        }
      }
    },
    getYearList() {
      let now = new Date()
      // now.setMonth(now.getMonth() + 2)
      this.thisYear = now.getFullYear()
      this.thisMonth = now.getMonth() + 1

      // console.log(this.thisYear ,this.thisMonth)

      now.setFullYear(now.getFullYear() - 1)
      if((now.getMonth() + 2) > 12){
        this.lastYear = now.getFullYear() + 1
        this.lastMonth = now.getMonth() + 2 - 12
      }else{
        this.lastYear = now.getFullYear()
        this.lastMonth = now.getMonth() + 2
      }

      // console.log(this.lastYear ,this.lastMonth)

      if(this.thisYear == this.lastYear){
        this.yy.push(this.thisYear)
        this.sameYear = true
      }else{
        this.yy.push(this.lastYear)
        this.yy.push(this.thisYear)
      }
    },
    async getAuthContactInfo () {
			this.contactInfo = ''
			this.userInfo = ''
        const payload = {
          token_id: sessionStorage.getItem('accessToken')
        }
        await this.$store.dispatch('gigaoffice/getAuthContactInfo', payload).then(res => {
					if (res.count === 0) {
						alert('등록된 고객정보가 없습니다. 고객정보를 등록해주세요.')
					} else {
						this.contactInfo = res.data.result
						if (this.contactInfo.partyIdNumber1 != null && this.contactInfo.partyIdNumber1 !== '') {
							this.partyIdNumber1 = this.contactInfo.partyIdNumber1
						}
						if (this.contactInfo.encPartyIdNumber1 != null && this.contactInfo.encPartyIdNumber1 !== '') {
							this.encPartyIdNumber1 = this.contactInfo.encPartyIdNumber1
						}
						if (this.contactInfo.partyIdNumber2 != null && this.contactInfo.partyIdNumber2 !== '') {
							this.partyIdNumber2 = this.contactInfo.partyIdNumber2
						}
						if (this.contactInfo.encPartyIdNumber2 != null && this.contactInfo.encPartyIdNumber2 !== '') {
							this.encPartyIdNumber2 = this.contactInfo.encPartyIdNumber2
						}
						// 임시 저장
						this.selectEmail = this.contactInfo.email
						this.selectMobile = this.contactInfo.mobileNumber
						this.selectPhone = this.contactInfo.phoneNumber

						// this.partyIdNumber1 = res.data.result.partyIdNumber1
						// this.partyIdNumber2 = res.data.result.partyIdNumber2
						this.partyDetailTypeCd = res.data.result.partyDetailTypeCd
						this.partyName = res.data.result.partyName
						/// / 고객정보 에서 코드 값 보정 시퀀스 ///
						if (this.partyDetailTypeCd === '03') {
							this.partyDetailTypeCd = '3'
						}
						if (this.partyDetailTypeCd === '08') {
							this.partyDetailTypeCd = '8'
						}
						if (this.partyDetailTypeCd === '06') {
							this.partyDetailTypeCd = '3'
						}
						if ((this.partyIdNumber2 === '') || (this.partyIdNumber2 === undefined) || (this.partyIdNumber2 === null)) {
							this.partyIdNumber2 = this.partyIdNumber1
						}
            if ((this.encPartyIdNumber2 === '') || (this.encPartyIdNumber2 === undefined) || (this.encPartyIdNumber2 === null)) {
							this.encPartyIdNumber2 = this.encPartyIdNumber1
            }
					}
				})
				.catch(error => {
					console.log(error)
				})
    },
    // 고객 정보 조회
    async getUserInfo () { // custId = cust_ind
			const payload = {}
			await this.$store.dispatch('gigaoffice/getUserInfo', payload).then(res => {
				if (res.data.code === '100') {
					if (res.data.count === '0') {
						return false
					}
					this.userInfo = res.data.result
          this.custid = res.data.cust_ind[0]

					// (custType 사업자번호:8/법인번호:3, custNum 사업자번호(10자리)/법인번호(13자리))
				} else {
					alert(res.data.message)
				}
			})
			// this.contactInfo = res
      .catch (err => {
        console.log(err)
      })
    },
    async getUserSvcInfo () {
      try {
        let param = {} ,temp = [] ,key = 'scn';
        // TODO TEST
        param = {
          custType: 3,
          custNum: this.encPartyIdNumber2
        };
        const res1 = await this.$store.dispatch("gigaoffice/getUserSvcInfo", param);
        
        // TODO TEST
        param = {
          custType: 8,
          custNum: this.encPartyIdNumber1
        };
        const res2 = await this.$store.dispatch("gigaoffice/getUserSvcInfo", param);

        if(res1 !== undefined && res1.length > 0){
            res1.forEach(function(value ,index){
            temp.push(value)
            })
        }
        if(res2 !== undefined && res2.length > 0){
            res2.forEach(function(value ,index){
            temp.push(value)
            })
        }
        temp = temp.reduce((i ,item) => {
          const removed = i.filter(j => j[key] !== item[key])
          return [...removed ,item]
        }, [])
        this.userSvcInfo = temp
        // if (res.length > 0) {
        //   this.userSvcInfo = res;
        // }
      } catch (err) {
        console.log(err);
      }
		},
    // 청구정보 조회
    async getAccountListInfo () {
      try {
        const param = { custId: this.custid }
        const res = await this.$store.dispatch('gigaoffice/getAccountListInfo', param)
        this.accountInfo = res
      } catch (err) {
        console.log(err)
      }
    },
    //요금기본정보상단(청구월별)
    async retrieveChageTxnByBillTgtYm () {
      let payload =  
        {
          billTgtDay : "1",//1달치:1 2달치:2
          billAccId : this.billAccId_sel,//청구계정아이디
          billContSttusCd : "",//청구계약상태코드
          svcContId : "",//서비스계약아이디
          endUserIp : "1.1.1.1"//사용자IP
        }
			await this.$store.dispatch('gigaoffice/retrieveChageTxnByBillTgtYm', payload).then(res => {
				console.log(res)
        this.txnByBillTgtYm_res = res
        this.year_res = this.year_sel
        this.month_res = this.month_sel
			})
      .catch (err => {
        console.log(err)
      })
		},
    //요금기본정보하단(청구상품별)
    async chageWebService () {
      if(this.billAccId_sel == '') {
        alert('청구계정ID를 선택해주세요.')
        return false
      }
      if(this.year_sel == '') {
        alert('조회하실 기간(년도)을 선택해주세요.')
        return false
      }
      if(this.month_sel == '') {
        alert('조회하실 기간(월)을 선택해주세요.')
        return false
      }
			let payload =  
        {
          //"청구대상년월"
          billTgtYm : this.year_sel + this.month_sel,
          //"청구계정아이디"
          billAccId : this.billAccId_sel,
          custId: this.custid
        }
      await this.$store.dispatch('gigaoffice/chageWebService', payload).then(res => {
        this.chageWebService_res = res.data.response.webSvcChageArr
        this.year_res = this.year_sel
        this.month_res = this.month_sel
        
        this.total_res = this.chageWebService_res.filter(item => item.dtlCboType == 'TOTAL')[0]
			})
      .catch (err => {
        console.log(err)
      })
		},
    //요금기본정보하단(서비스계약번호별)
    async chageBySvcWebService () {
      if(this.billAccId_sel == '') {
        alert('청구계정ID를 선택해주세요.')
        return false
      }
      if(this.svcContId_sel == '') {
        alert('서비스계약ID를 선택해주세요.')
        return false
      }
      if(this.year_sel == '') {
        alert('조회하실 기간(년도)을 선택해주세요.')
        return false
      }
      if(this.month_sel == '') {
        alert('조회하실 기간(월)을 선택해주세요.')
        return false
      }
      let payload =  
        {
          // 청구대상년월
          billTgtYm : this.year_sel + this.month_sel
          // 청구계정아이디
          , billAccId : this.billAccId_sel
          // 서비스계약아이디 scn
          , svcContId : this.svcContId_sel
        }
			await this.$store.dispatch('gigaoffice/chageBySvcWebService', payload).then(res => {
        // console.log('chageWebService_res : ', res)
        this.chageWebService_res = res.data.response.webSvcChageArr
        this.year_res = this.year_sel
        this.month_res = this.month_sel

        if(this.chageWebService_res != undefined){
          this.total_res = this.chageWebService_res.filter(item => item.dtlCboType == 'TOTAL')[0]
        }        
			})
      .catch (err => {
        console.log(err)
      })
		},
    selectValue(e, type) {
			let v = e.currentTarget.getAttribute('value')
      switch (type) {
      case 'ID' :
        this.billAccId_sel = v
        break;
      case 'SCN' :
        this.svcContId_sel = v
        break;
      case 'YY' :
        this.year_sel = v
        this.mm = []
        this.getMonthList(v)
        this.month_sel = ''
        this.$refs.mm.innerHTML = `<span class="fcGy">월</span>`
        break;
      case 'MM' :
        this.month_sel = v
        break;
      }
    },
  }
}
</script>
<style>
  .feePrstus .svcAmount dl {
    height: auto;
  }
</style>